.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.link:hover {
    color: darkblue;
}

[data-amplify-authenticator] [data-amplify-container] {
    padding-top: 120px;
}
